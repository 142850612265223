.input {
  width: 100%;
  min-height: 2.625rem;
  text-align: left;
  box-sizing: border-box;
  outline: 0;
  padding: 0.5rem 1rem;
  border: 1px solid #E0E0E0;
  background: transparent;
  font-size: 0.875rem;
  color: #fff;
}
