.bets {
  margin-top: 1rem;
  &__user-id-input{
    margin: 0 auto;
    width: 90%;
    input{
      margin-top: .2rem;
      border-radius: 5px;
    }
  }
  &__pagination{
    width: 100%;
  }
}