.change-pas {
    width: 100%;
    box-sizing: border-box;
    padding: 0rem 2rem;
    padding-top: 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__button {
      margin-top: 5rem;
      width: 80%;
      height: 3rem;
      background: #FFD500;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      color: #000;
      cursor: pointer;
    }
    &__text-pass {
      margin-bottom: 0.5rem;
    }
  }
  