.slots-paginator {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.25rem;
  position: relative;
  user-select: none;
  max-width: 10rem;
  width: 100%;
  margin: 3rem auto;

  &__title{ margin-top: 1rem }

  &__pages {
    width: 6rem;
    border: 1px solid #fff;
    border-radius: 0.625rem;
    position: absolute;
    background: #464646;
    box-sizing: border-box;
    cursor: pointer;
    z-index: 3;
    top: -0.15rem;
    right: 0;
    max-height: 10rem;
    overflow-y: auto;
  }
  &__arrow svg {
    display: block;
    width: 0.5rem;
    transition: transform 0.2s ease-in;
  }
  &_open &__arrow svg { transform: rotate(180deg) }
  &__current-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0.5625rem;
    background: #464646;;
    color: #FFFFFF;
    height: 3rem;
  }
  &__page {
    padding: 0.25rem 0.5625rem;
    height: 1.5rem;
    color: #FFFFFF;
    border-top: 1px solid #fff;
    &_active {
      background: #1A5BBC;
      color: #FFFFFF;
    }
    &:hover { background: #c4c4c4 }
  }
}