.table {
  width: 90%;
  margin: 2rem auto 0 auto;

  &__row {
    border: 1px solid #fff;
    border-radius: 5px;
    display: flex;
    margin-bottom: .5rem;
    justify-content: space-between;
    padding: 1rem;
    &-p {
      margin-bottom: .5rem;
      &:last-child{ margin-bottom: 0 }
    }
    &-span { color: #f1ea0c }
    &:last-child { margin-bottom: 0 }
    &-part_one{ margin-right: 1rem }
  }
  &__no-bets { text-align: center }
}