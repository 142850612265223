.button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 0.75rem;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  font-family: 'Roboto Condensed', sans-serif;
  user-select: none;
  text-align: center;
  background: #FFB401;
  color: #fff;
  outline: none;

  &:active {
    transform: translateY(2px);
  }

  &_colors {
    &_default {
      border: 1px solid #263154;
      border-radius: 5px;
    }

    &_default1 {
      border: none;
      text-transform: none;
    }

    &_green {
      background: #23a152;
      color: #fff;
      border: none;
    }

    &_black {
      background: #263154;
      border-radius: 5px;
      border: none;
      color: #fff;
    }

    &_dark {
      border: 1px solid #263154;
      background: #263154;
      border-radius: 5px;
    }

    &_red {
      background: #ff4b55;
      border: none;
    }

    &_gold {
      background: #FFB401;
      color: #fff;
      border: none;
      border-radius: 5px;
    }

    &_dark-purple {
      background: #323241;
    }

    &_light-purple {
      background: #595979;
    }

    &_purple {
      background: #47475F;

      &:hover {
        background: #595979;
      }
    }

    &_button-header {
      background: #263154;
      color: #fff;
      border-radius: 5px;

      &:hover {
        background-color: #595979;
      }
    }

    &_register {
      background: #dbb70a;
      color: black;
      border: none;
      padding-left: 1.5rem;
    }
  }

  &_sizable {
    &_default {
      height: 2rem;
    }

    &_low {
      height: 2.5rem;
    }

    &_high {
      height: 3rem;
    }

    &_sign {
      height: 2rem;
    }
  }

  &_disable {
    background: #121931;
    cursor: not-allowed;
  }
}